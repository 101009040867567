.reports {
    display: flex;
    flex-grow: 1;

    .report {
        flex-grow: 1;
        display: flex;

        .report-inner {
            flex-grow: 1;
        }
    }
}
