.pipe-BLUE {
    --pipe-color-light: var(--blue-light);
}

.pipe-BROWN {
    --pipe-color-light: var(--brown-light);
}

.pipe-YELLOW {
    --pipe-color-light: var(--yellow-light);
}

.pipe-WHITE {
    --pipe-color-light: var(--white-light);
}

.pipe-RED {
    --pipe-color-light: var(--red-light);
}

.pipe-GREEN {
    --pipe-color-light: var(--green-light);
}
