.component-item {
    //     // overflow: auto;
    -webkit-box-shadow: 0 2px 8px #f0f1f2;
    box-shadow: 0 2px 8px #f0f1f2;
}

.react-grid-item > .react-resizable-handle {
    z-index: 1000;
}

.react-resizable-handle::after {
    border-right: 2px solid red !important;
    border-bottom: 2px solid red !important;
}
