@import "globalSass.scss";

.tile-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 55px;
    grid-row-gap: 55px;

    .tile-container-element {
        @include outer-box-shadow-bigger;

        box-sizing: border-box;
        padding: 20px;

        align-items: center;
        height: 190px;
        width: 190px;
        background-color: var(--bckg);

        color: var(--gray-darkest);
        text-transform: capitalize;
        justify-content: center;
        display: flex;
        font-size: var(--font-size-large);

        &:hover {
            @include animation-short;
            @include inner-box-shadow-bigger;
        }
    }
}

@media screen and (max-width: 1000px) /* Tablet */ {
    .tile-container {
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 40px;
        grid-row-gap: 40px;
        padding-left: 50px;
        padding-right: 50px;

        .tile-container-element {
            height: 170px;
            width: 170px;
        }
    }
}

@media screen and (max-width: 700px) /* Mobile Apple */ {
    .tile-container {
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 30px;
        grid-row-gap: 30px;
        padding-left: 50px;
        padding-right: 50px;

        .tile-container-element {
            height: 170px;
            width: 170px;
        }
    }
}

@media screen and (max-width: 500px) /* Mobile */ {
    .tile-container {
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 30px;
        grid-row-gap: 30px;
        padding-left: 40px;
        padding-right: 40px;

        .tile-container-element {
            height: 140px;
            width: 140px;
        }
    }
}
