.App {
    text-align: center;
}

.root-container {
    display: flex;
    flex: auto;
}

.form-options-together {
    background-color: rgba(191, 191, 191, 0.15);
    margin: 10px;
    padding: 20px;
}

.text-align-center input {
    text-align: center;
}

#root {
    display: grid;
    height: 100vh;
    grid-template-rows: auto 1fr auto;
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.fadeOut {
    animation: fadeOut ease-in;
    animation-fill-mode: forwards;
    animation-duration: 1s;
}

.clickable {
    cursor: pointer;
}

a {
    text-decoration: none;
}
