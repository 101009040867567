@import "../css/globalSass.scss";

.navigation-sidebar {
    overflow: auto;
    width: 0px;
    height: calc(100vh - var(--header-height) - 10px);
    opacity: 0;

    background: var(--bckg);

    transition: width 1s, opacity 1s ease-in-out, display 2s;

    box-shadow: var(--outer-box-shadow);
    border-top-right-radius: var(--border-radius-large);

    &::-webkit-scrollbar {
        display: none;
    }

    .dx-accordion-item-title {
        height: 70px;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 20px;
        font-size: var(--font-size-large);
        font-weight: normal;
    }

    &.sidebar-open {
        width: var(--nav-bar-width);
        opacity: 1;
    }

    .item-button {
        height: 46px;
        display: flex;
        align-items: center;
        position: relative;

        .report-button {
            height: 46px;
            display: flex;
            align-items: center;

            .button-gap {
                width: 7px;
            }

            svg {
                padding-bottom: 2px;
            }

            &.state-normal {
                svg {
                    opacity: 0;
                }
            }

            &.state-warning {
                .circle-center {
                    fill: var(--warning);
                }
            }

            &.state-error {
                .circle-center {
                    fill: var(--error);
                }
            }
        }

        span {
            color: var(--gray-darkest);
            font-size: var(--font-size-medium);
            position: absolute;
            left: 40px;
            pointer-events: none;
        }

        .item-button-shield {
            @include transition-short;
            position: absolute;
            width: var(--nav-bar-width);
            height: 46px;
            left: -30px;

            &:hover {
                @include inner-box-shadow-smaller;
            }
        }

        &.selected-item {
            .item-button-shield {
                @include inner-box-shadow-smaller;
            }
        }
    }

    .dx-accordion {
        background: var(--bckg);

        .dx-accordion-item-opened .dx-accordion-item-body {
            padding: unset;
        }

        .dx-accordion-item {
            border: unset;

            ::before {
                display: none;
            }
        }
    }
}

@media screen and (max-width: 600px) /* Mobile Apple */ {
    .navigation-sidebar {
        position: fixed;
        z-index: 1500;
        border-radius: 0px 0px 0px 0px;
        overflow-y: scroll;
        overflow-x: hidden;
        height: calc(100% - var(--header-height));

        &.sidebar-open {
            width: 100%;
        }
        .item-button {
            .item-button-shield {
                width: 100%;
            }
        }
    }
}
