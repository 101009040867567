.editor-container {
    padding: 7px 9px 8px;
    border: 1px solid rgb(221, 221, 221);
    border-radius: 4px;

    &:hover {
        border-color: rgba(51, 122, 183, 0.4);
    }

    &:focus-within {
        border-color: rgb(52, 122, 183);
    }
}

.editor-col {
    display: flex;

    &__editor {
        flex-grow: 1;
        min-width: 400px;
    }

    &__btn {
        margin-left: var(0.5rem);
        align-self: center;
    }
}

.btn-confirm {
    text-align: left;
    margin-top: var(0.5rem);
}
