.selectbox-component {
    input {
        padding: 1px !important;
        min-height: 0 !important;
        width: 100%;
    }
}

.bold input {
    font-weight: bold;
}

.italic input {
    font-style: italic;
}
