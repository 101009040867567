.pump {
    .medium {
        fill: var(--gray-medium);
    }
    .main {
        fill: var(--gray-dark);
        transition: fill 1s;
    }

    .shadows {
        fill: #fff;
        opacity: 0.14;
    }

    .ribbing {
        fill: var(--gray-dark);
    }

    .roof {
        fill: var(--gray-light);
    }

    .roof-light {
        fill: var(--gray-lightest);
    }

    &.pump-error .main {
        fill: var(--error);
    }

    &.pump-active .main {
        fill: var(--success);
    }
}
