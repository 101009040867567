@import "../../css/globalSass.scss";

.flex-columns-container {
    height: 100%;
    display: flex;
    flex-direction: row;
    > div {
        margin-left: var(--inner-component-margin);
        margin-right: var(--inner-component-margin);
    }
}
