.ventilator-div {
    background-color: blue;
}

.ventilator-stator {
    // position: absolute;
    fill-rule: evenodd;
    clip-rule: evenodd;
    stroke-linejoin: round;
    stroke-miterlimit: 2;

    .background {
        fill: var(--bckg);
    }
    .box {
        fill: var(--gray-medium);
    }
    .middle {
        fill: var(--gray-dark);
    }
    .shadow {
        fill: #fff;
        fill-opacity: 0.35;
    }
}

.ventilator-rotor {
    position: absolute;
    left: 11.5%;
    width: 77%;
    height: 77%;
    fill-rule: evenodd;
    clip-rule: evenodd;
    stroke-linejoin: round;
    stroke-miterlimit: 2;

    // transition: all 5s;
    animation: rotation linear infinite;
    transform-origin: 50% 50%;
    display: inline-block;

    g g path:nth-child(1) {
        fill: var(--gray-light);
    }

    g g path:nth-child(2) {
        fill: #fff;
        fill-opacity: 0.2;
    }
}
