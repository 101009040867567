.chartjs-component {
    position: relative;
    display: flex;
    flex-grow: 1;

    .chartjs-buttons {
        left: 56px;
        position: absolute;

        .dx-button-content {
            padding: 2px;
        }
    }
}
