.component-setup-container {
    margin: 20px;
}

.library_component_preview {
    height: 500px;
    img {
        z-index: -100;
    }
}
