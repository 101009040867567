@import "../../css/globalSass.scss";

.flex-rows-container {
    display: flex;
    flex-direction: column;
    height: 100%;

    > div {
        &:last-child {
            flex-grow: 1;
        }
        margin-top: var(--inner-component-margin);
        margin-bottom: var(--inner-component-margin);
        justify-content: center;
    }
}
