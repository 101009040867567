.blower {
    .main {
        fill: var(--gray-medium);
        transition: fill 1s;
    }
    .medium {
        fill: var(--gray-medium);
    }

    .ribbing {
        fill: var(--gray-dark);
    }

    &.blower-error .main {
        fill: var(--error);
    }

    &.blower-active .main {
        fill: var(--success);
    }
}
