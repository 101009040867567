@import "globalSass.scss";

.max-width-height {
    width: 100%;
    height: 100%;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

// globalni velikosti pisma (komponenty)
.font-size-small {
    font-size: var(--font-size-small);
}

.font-size-medium {
    font-size: var(--font-size-medium);
}

.font-size-large {
    font-size: var(--font-size-large);
}

// (systemove)

//tridy box shadow

.inner-box-shadow-smaller {
    @include inner-box-shadow-smaller;
}

.inner-box-shadow-bigger {
    @include inner-box-shadow-bigger;
}

.outer-box-shadow-smaller {
    @include outer-box-shadow-smaller;
}

.outer-box-shadow-bigger {
    @include outer-box-shadow-bigger;
}
