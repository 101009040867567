.button-group-component {
    .dx-button-content {
        padding: 2px !important;
    }

    &.font-size-small {
        span {
            font-size: var(--font-size-small);
        }
    }
    &.font-size-medium {
        span {
            font-size: var(--font-size-medium);
        }
    }
    &.font-size-large {
        span {
            font-size: var(--font-size-large);
        }
    }
}
