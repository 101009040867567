@import "globalSass.scss";

.form-outer {
    display: flex;
    align-items: center;
    background: #f3f3f3;
    padding: 30px;
    height: 420px;
    width: 80%;
    max-width: 510px;
    position: absolute;
    padding: 0px;
    border-radius: var(--border-radius-large);
    box-shadow: -21px -16px 18px -7px rgba(255, 255, 255, 0.47),
        20px 17px 30px -8px rgba(180, 180, 180, 0.4);

    input {
        text-align: center;
        background: #f3f3f3;
        height: 50px;
        border-radius: var(--border-radius-smaller);
        box-shadow: inset -4px -2px 7px 4px rgba(255, 255, 255, 0.51),
            inset 6px 6px 7px 2px rgba(180, 180, 180, 0.4);
    }

    .dx-placeholder {
        width: 100%;
        text-align: center;
    }

    .form-inner {
        height: 100%;
        display: flex;
        align-items: stretch;
        flex-direction: column;
        margin: 0 auto;
        width: 435px;
        padding: unset;

        .logo {
            padding-top: 30px;
            flex-grow: 5;
            display: table;
            margin: 0 auto;
            height: 50px;
            width: 153px;
            margin-bottom: 15px;
        }

        .dx-form {
            flex-grow: 2;

            .login-fields-delimiter {
                height: 15px;
            }

            .dx-texteditor.dx-editor-outlined {
                font-size: var(--font-size-medium);
                background: unset;
                border: unset;
                border-radius: unset;
            }
        }

        .login-buttons {
            flex-grow: 0.5;

            .login-button-password {
                margin-right: 167px;
            }

            .dx-button-has-text .dx-button-content {
                padding: unset;
                font-size: var(--font-size-medium);
            }

            .dx-button-default.dx-button-mode-contained {
                width: 123px;
                height: 51px;
                border-radius: 12px;
                //position: absolute;
            }
        }

        .version {
            padding-top: 20px;
            padding-bottom: 10px;
            flex-grow: 0.5;
            color: var(--gray-dark);
            float: right;
            font-size: var(--font-size-small);
            margin: 0 5px 5px 0;
        }
    }
}

.wrapper {
    display: flex;
    position: absolute;
    //top: -10%;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    min-height: 100%;
}

.fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

/* Simple CSS3 Fade-in Animation */
@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@-moz-keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.fadeIn {
    opacity: 0;
    -webkit-animation: fadeIn ease-in 1;
    -moz-animation: fadeIn ease-in 1;
    animation: fadeIn ease-in 1;

    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;

    -webkit-animation-duration: 1s;
    -moz-animation-duration: 1s;
    animation-duration: 1s;
}

.fadeIn.first {
    -webkit-animation-delay: 0.4s;
    -moz-animation-delay: 0.4s;
    animation-delay: 0.4s;
}

.fadeIn.second {
    -webkit-animation-delay: 0.6s;
    -moz-animation-delay: 0.6s;
    animation-delay: 0.6s;
}

.fadeIn.third {
    -webkit-animation-delay: 0.8s;
    -moz-animation-delay: 0.8s;
    animation-delay: 0.8s;
}

.fadeIn.fourth {
    -webkit-animation-delay: 1s;
    -moz-animation-delay: 1s;
    animation-delay: 1s;
}

/* Simple CSS3 Fade-in Animation */
.underlineHover:after {
    display: block;
    left: 0;
    bottom: -10px;
    width: 0;
    height: 2px;
    background-color: #56baed;
    content: "";
    transition: width 0.2s;
}

@media screen and (max-width: 700px) /* Mobile Apple */ {
    .form-outer {
        .form-inner {
            width: 370px;
            .login-buttons {
                .login-button-password {
                    margin-right: 118px;
                }
                .dx-button-has-text .dx-button-content {
                    font-size: var(--font-size-medium);
                }
            }
        }
    }
}

@media screen and (max-width: 500px) /* Mobile */ {
    .form-outer {
        .form-inner {
            width: 270px;
            .login-buttons {
                .login-button-password {
                    margin-right: 26px;
                }
                .dx-button-has-text .dx-button-content {
                    font-size: var(--font-size-medium);
                }
            }
        }
    }
}
