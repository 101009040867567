.gauge-switch {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .text-container {
        flex: 0.2;
        display: flex;

        .bold {
            font-weight: bold;
        }

        .italic {
            font-style: italic;
        }

        .gauge-switch-active {
            color: var(--primary);
        }

        div {
            flex: 1;
            display: flex;
            span {
                width: 100%;
                cursor: pointer;
                transition: color 0.5s;
            }

            span.text-bottom {
                align-self: flex-end;
            }
        }
    }

    svg {
        flex: 1;
        transform-origin: 50% 57%;
        transition: transform 0.5s;
        cursor: pointer;

        .medium {
            fill: var(--gray-medium);
        }
        .light {
            fill: var(--gray-light);
        }
        .lightest {
            fill: var(--gray-lightest);
        }
    }

    &-left svg {
        transform: rotate(-40deg);
    }
    &-right svg {
        transform: rotate(40deg);
    }
}
