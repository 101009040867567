.text-align-center {
    text-align: center;
}

.heading-1 {
    font-size: var(--font-size-xxlarge);
    display: inline-block;
    margin-top: 20px;
    margin-bottom: 40px;
    font-weight: bold;
    letter-spacing: 1px;
    color: black;
}

.heading-2 {
    font-size: var(--font-size-large);
    display: inline-block;
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: bold;
}
