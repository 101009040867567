.list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        div {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }

    .dx-button-content {
        padding: 3px;
    }
}
