.report-management {
    .toolbar-buttons {
        display: flex;
        position: fixed;
        z-index: 1400;
        top: var(--header-height);
        right: 0px;
        pointer-events: none;

        .edit-button {
            z-index: 1450;
            pointer-events: auto;
        }

        .additional-buttons {
            visibility: hidden;
            opacity: 0;
            transform: translate(100%, 0);
            transition: all 1s;
            pointer-events: auto;
        }
    }

    .button-active {
        .dx-icon {
            color: rgb(255, 208, 0);
        }
    }

    .bottom-menu-outer {
        // relative zajisti, ze se dalsi potomci napr. pomoci fixed
        // chytaji na misto tohoto relative (a ne pres celou obr.)
        position: relative;
        z-index: 1400;
        display: none;

        &-active {
            display: block;
        }

        .bottom-menu-inner {
            // visibility: hidden;
            z-index: 1500;
            position: fixed;
            bottom: 0px;
            transition: transform 1s;
            transform: translate(0, 100%);

            .add-button-outer {
                display: flex;
                position: fixed;
                top: -77px;
                width: 100%;

                .add-button {
                    z-index: 1600;
                    // right: 0px;
                    // top: -80px;
                    border-radius: 50%;
                    transition: all 1s;

                    .dx-button-content {
                        size: 30px;
                        padding: 20px;

                        .dx-icon {
                            font-size: var(--font-size-xxlarge);
                            line-height: normal;
                            width: auto;
                            height: auto;
                        }
                    }
                }
            }

            .library {
                position: relative;
                display: flex;
                flex-direction: row;
                bottom: 0px;
                height: 250px;
                background-color: var(--bckg);
                transition: all 1s;

                .library-left_section {
                    display: flex;
                    flex-direction: column;
                    background-color: white;
                }

                .library-right_section {
                    display: flex;
                    flex-direction: column;
                    width: 100%;

                    .search-line {
                        background-color: white;
                        .dx-textbox {
                            float: right;
                        }
                    }

                    .library-components {
                        display: flex;
                        flex-wrap: wrap;
                        overflow: auto;

                        .library-component-container {
                            display: flex;
                            flex-direction: column;
                            margin: 10px;

                            .library-component-parent {
                                position: sticky;
                                overflow: hidden;
                                cursor: move;
                            }

                            .library-component-label {
                                overflow: hidden;
                                font-size: var(--font-size-small);
                                text-align: center;
                            }
                        }
                    }
                }
            }

            &-active {
                transform: translate(0, 0);

                .add-button {
                    transform: rotate(225deg);
                }
            }
        }
    }

    &-active {
        .toolbar-buttons {
            .additional-buttons {
                visibility: visible;
                opacity: 1;
                transform: translate(0, 0);
            }
        }

        .bottom-menu {
            visibility: visible;
        }
    }
}
