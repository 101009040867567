.image-component {
    user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;

    transition: opacity 1s;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
}
