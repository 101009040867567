@import "../css/globalSass.scss";

@mixin widgets-container {
    position: absolute;
    z-index: 1900;
    display: flex;
    opacity: 0;
    transition: all var(--transition-long);

    &:hover {
        opacity: 1;
    }
}

.middle-group {
    position: relative;
    display: flex;

    svg {
        path {
            fill: var(--gray-medium);
        }
    }

    .widget-button {
        @include header-icon;

        display: flex;
        align-items: center;

        &:hover {
            @include animation-short;
            @include inner-box-shadow-smaller;
        }
    }

    &:hover {
        .left-widgets {
            opacity: 1;
            left: -58px;
        }

        .relative-holder {
            .right-widgets {
                opacity: 1;
                right: -58px;
            }
        }
    }

    .left-widgets {
        @include widgets-container;
        padding-right: 20px;
        left: 0px;

        .calculator {
            cursor: pointer;
        }
    }

    .logo-button {
        transition: all var(--transition-long);
        width: 56px;
        height: 36px;
        z-index: 2000;

        &:hover {
            //filter: drop-shadow(2px 2px 2px rgb(131, 129, 243));
            transform: scale(1.2);
        }
    }

    .relative-holder {
        z-index: 1900;
        position: relative;

        .right-widgets {
            @include widgets-container;
            padding-left: 20px;
            right: 0px;

            .tutorials {
                cursor: pointer;
            }
        }
    }
}
