.stirrer {
    .main {
        fill: var(--gray-dark);
        > path {
            transition: fill 1s;
        }
    }

    .rotor {
        fill: var(--gray-medium);
    }

    .shadows {
        fill: #fff;

        .first {
            fill-opacity: 0.13;
        }
        .second {
            fill-opacity: 0.21;
        }
    }

    .blades {
        fill: var(--gray-light);
    }

    &.stirrer-error .main {
        fill: var(--error);
    }

    &.stirrer-active .main {
        fill: var(--success);
    }
}
