.flap #line {
    transition: transform 1s;
}

.flap #body {
    transition: fill 1s;
}

.flap-open {
    #body {
        fill: var(--success) !important;
    }
    #line {
        transform: rotate(0deg);
        transform-origin: 50% 50%;
    }
}

.flap-open.flap-vertical {
    #line {
        transform: rotate(90deg);
        transform-origin: 50% 50%;
    }
}

.flap-middle {
    #body {
        fill: var(--blue-second) !important;
    }
    #line {
        transform: rotate(45deg) !important;
        transform-origin: 50% 50%;
    }
}

.flap-error {
    #body {
        fill: var(--error) !important;
    }
    #line {
        transform: rotate(45deg);
        transform-origin: 50% 50%;
    }
}

.flap-closed {
    #line {
        transform: rotate(90deg);
        transform-origin: 50% 50%;
    }
}

.flap-closed.flap-vertical {
    #line {
        transform: rotate(0deg);
        transform-origin: 50% 50%;
    }
}
