@import "../css/globalSass.scss";

.profile-option-button {
    @include transition-short;
    cursor: pointer;
    padding: 15px;
    border-radius: var(--border-radius-smaller);
    &:hover {
        @include inner-box-shadow-smaller;
    }
}
