.dx-toolbar {
    background-color: transparent !important;
}

.report-menu-toolbar {
    height: 0;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    -webkit-transition: opacity 0.5s ease-in-out;
}

div.report-layout:hover div.report-menu-toolbar {
    display: block;
    opacity: 1;
}
