@import "globalSass.scss";

.dx-button-default.dx-button-mode-contained {
    background-color: var(--primary);

    &:hover {
        background-color: var(--primary-dark);
    }
}

.dx-button-default.dx-button-mode-text {
    color: var(--primary);

    &:hover {
        color: var(--primary-dark);
        background-color: transparent;
    }
}

.dx-popup-wrapper > .dx-overlay-content {
    background-color: var(--bckg);
    font-size: var(--font-size-medium);
    font-family: "Roboto";
    color: var(--gray-darkest);
    padding: 5px;
    border-radius: var(--border-radius-smaller);
    -webkit-box-shadow: 0px 0px 17px 0px rgba(122, 122, 122, 0.3);
    -moz-box-shadow: 0px 0px 17px 0px rgba(122, 122, 122, 0.3);
    box-shadow: 0px 0px 17px 0px rgba(122, 122, 122, 0.3);
    border: none;
}

.dx-popup-title {
    border-bottom: none;
}

.dx-popup-wrapper .dx-state-focused.dx-overlay-content {
    border: 0px;
}

.dx-toast-message {
    white-space: pre-line;
}

.dx-state-disabled .dx-widget,
.dx-state-disabled.dx-widget {
    opacity: 0.9;
}

.dx-datagrid {
    padding: 10px;
}

.dx-widget {
    font-family: "Roboto" !important;
}

.dx-widget input {
    font-family: "Roboto" !important;
}

//přepis shaderu u pop-upu
.dx-overlay-shader {
    background-color: unset;
}

//tabulka
.marq {
    .dx-datagrid {
        border-radius: var(--border-radius-large);

        // spolecny div pro hlavicku i radky
        .dx-datagrid-nowrap {
            border: none;
            padding: 10px;

            td {
                border: none;
                padding-left: 20px;
                padding-right: 20px;
            }

            .dx-checkbox-icon {
                background-color: transparent;
                border: 2px solid var(--gray-light);
                &::before {
                    background-color: var(--primary);
                }
            }

            .dx-checkbox-checked {
                .dx-checkbox-icon {
                    color: white;
                    background-color: var(--primary);
                    border-color: var(--primary);
                }
            }
        }

        // lista nad hlavickou
        .dx-datagrid-header-panel {
            padding-left: 34px;
            padding-right: 34px;
            .table-title {
                font-size: var(--font-size-large);
                color: var(--gray-darkest);
                font-weight: bold;
                font-family: "Roboto";
            }
        }

        // tabulka hlavicky
        .dx-datagrid-headers {
            padding-top: 0;
            padding-bottom: 0;
            padding-right: 6px !important;
            padding-left: 6px;
            margin-bottom: 5px;

            .dx-datagrid-content {
                background-color: var(--bckg);
                border-radius: var(--border-radius-large);
                color: var(--gray-darkest);
                font-weight: bold;
            }
        }

        // tabulka radku
        .dx-datagrid-rowsview {
            padding: 0;

            .dx-datagrid-content {
                table {
                    border-collapse: separate;
                    // kdyz se odcommentuje nize border-spacing, tak je tabulka tak,
                    // jak byla nastylovana poprve po redesignu
                    // border-spacing: 0 6px;
                    padding-right: 6px;
                    padding-left: 6px;

                    tbody {
                        tr {
                            .dx-command-edit {
                                .dx-link {
                                    display: none;
                                }
                            }

                            .dx-icon-edit {
                                color: var(--warning);
                            }

                            .dx-icon-trash {
                                color: var(--error);
                            }

                            td {
                                color: var(--gray-darkest);
                            }

                            &:hover {
                                cursor: pointer;
                                box-shadow: 2px 2px 8px 2px #ededed;
                                border-radius: var(--border-radius-large);
                                .dx-link {
                                    display: inline-block;
                                }
                            }

                            &.dx-freespace-row {
                                display: none;
                            }

                            &.dx-selection {
                                &:not(.dx-row-focused) {
                                    > td {
                                        background-color: transparent;
                                        border: none;
                                    }
                                    border-radius: var(--border-radius-large);
                                    // TODO promenna nebo mixin i na border-radius
                                    box-shadow: inset 2px 2px 0px 20px var(--gray-white);
                                }
                            }

                            &.dx-group-row {
                                background-color: transparent;
                                border-radius: var(--border-radius-large);
                                box-shadow: inset 2px 2px 0px 20px var(--bckg);
                                color: var(--gray-medium);
                            }
                        }
                    }
                }
            }
        }
    }
}
//konec tabulky

.smaller-dx-button {
    .dx-button-content {
        padding: 4px;
    }
    .dx-item.dx-toolbar-item.dx-toolbar-button {
        padding: 0;
    }
}
