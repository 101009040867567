.cls-1 {
    fill: #9d9d9c;
}

.cls-2 {
    fill: #c7c6c6;
}

.pipe-not-active #pipe-water {
    rect,
    path {
        fill: var(--pipe-color-light);

        // problem s vykonem udajne resi tohle ----
        // transform: rotateZ(360deg);
        will-change: transform;
        // -----
    }
}

@keyframes water-pipe {
    from {
        fill: var(--pipe-color);
    }
    to {
        fill: var(--pipe-color-second);
    }
}

.pipe-animation #pipe-water {
    rect,
    path {
        animation: water-pipe 3s infinite alternate;
    }
}

.pipe-active #pipe-water {
    rect,
    path {
        fill: var(--pipe-color-second);
    }
}

.pipe-BLUE {
    --pipe-color: var(--blue);
    --pipe-color-second: var(--blue-second);
    --pipe-color-light: var(--blue-light);
}

.pipe-BROWN {
    --pipe-color: var(--brown);
    --pipe-color-second: var(--brown-second);
    --pipe-color-light: var(--brown-light);
}

.pipe-YELLOW {
    --pipe-color: var(--yellow);
    --pipe-color-second: var(--yellow-second);
    --pipe-color-light: var(--yellow-light);
}

.pipe-WHITE {
    --pipe-color: var(--white);
    --pipe-color-second: var(--white-second);
    --pipe-color-light: var(--white-light);
}

.pipe-RED {
    --pipe-color: var(--red);
    --pipe-color-second: var(--red-second);
    --pipe-color-light: var(--red-light);
}

.pipe-GREEN {
    --pipe-color: var(--green);
    --pipe-color-second: var(--green-second);
    --pipe-color-light: var(--green-light);
}

.pipe-horizontal {
    width: 100%;
}

.pipe-vertical {
    height: 100%;
}
