.administration-layout {
    display: flex;
    width: 100%;

    .administration-content {
        flex: 1;
        display: flex;
        // pokud by tu sirka nebyla, tak se tabulka natahne na maximalni sirku obrazovky
        width: 1%;
        padding: 20px;

        .base-collection-content {
            display: flex;
            flex-direction: column;
            flex: 1;

            .collection-table {
                flex: 1;
            }
        }
    }
}
