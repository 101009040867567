.edge-buttons {
    position: absolute;
    font-size: 12;
    // everything inside EdgeLabelRenderer has no pointer events by default
    // if you have an interactive element, set pointer-events: all
    pointer-events: all;
}

// tohle zajisti, ze jsou nody za trubkama
// .react-flow__node {
// z-index: -1 !important;
// }
