@mixin animation-short {
    animation: animation-short 0.2s;
}

@mixin transition-short {
    transition: var(--transition-short);
}

@mixin inner-box-shadow-smaller {
    box-shadow: var(--inner-box-shadow);
    border-radius: var(--border-radius-smaller);
}

@mixin inner-box-shadow-bigger {
    box-shadow: var(--inner-box-shadow);
    border-radius: var(--border-radius-large);
}

@mixin outer-box-shadow-smaller {
    box-shadow: var(--outer-box-shadow);
    border-radius: var(--border-radius-smaller);
}

@mixin outer-box-shadow-bigger {
    box-shadow: var(--outer-box-shadow);
    border-radius: var(--border-radius-large);
}

//header
@mixin header-icon {
    @include outer-box-shadow-smaller;

    justify-content: center;
    width: var(--header-icons-size);
    height: var(--header-icons-size);
}

@keyframes animation-short {
    0% {
        @include outer-box-shadow-bigger;
    }
    50% {
        box-shadow: var(--middle-box-shadow);
    }
    100% {
        @include inner-box-shadow-bigger;
    }
}
