.dx-toolbar {
}

div.component-layout {
    width: 100%;
    height: 100%;
}

.component-menu-button {
    opacity: 1;
    z-index: 5000;
    position: fixed;

    &--visible {
        display: flex;
        opacity: 1;
    }
}

.react-draggable-transparent-selection div.component-menu-button {
    opacity: 0.05;
}

.react-grid-item:hover {
    z-index: 1000;
}

.dx-toolbar-items-container {
}

.component-toolbar-test {
    width: auto;
    height: auto;
    opacity: 1;
    z-index: 1501;
    position: fixed;
    left: 0px;
    top: 0px;
    background-color: green;
    width: 50px;
    height: 30px;
}

.react-draggable-transparent-selection .component-layout--hovered {
    background-color: transparent;
}

.component-layout-overlap {
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    background-color: var(--primary-dark);
}

.component-layout-overlap--hovered {
    opacity: 0.2;
    transition: opacity 0.3s;
}
