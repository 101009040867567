:root {
    --header-height: 64px;
    --nav-bar-width: 254px;

    --primary: hsl(252, 100%, 50%);
    --primary-dark: hsl(252, 98%, 33%);
    --element: hsl(0, 0%, 100%);
    --default-blue: hsl(218, 100%, 88%);
    --success: hsl(93, 85%, 41%);
    --warning: hsl(49, 100%, 58%);
    --error: hsl(4, 84%, 55%);
    --bckg: #f3f3f3;
    --gray-white: #fafafa;
    --gray-lightest: #d4d4d4;
    --gray-light: #c6c6c6;
    --gray-medium: #9d9d9d;
    --gray-dark: #747474;
    --gray-darkest: #555555;

    // nasleduji component colors
    // Jakekoliv zmeny je potreba upravit i v ReactCSSVariables.tsx !!!
    --blue-origin: 219, 100%;
    --blue: hsl(var(--blue-origin), 85%);
    --blue-second: hsl(var(--blue-origin), 70%);
    --blue-light: hsl(var(--blue-origin), 93%);

    --brown-origin: 38, 50%;
    --brown: hsl(var(--brown-origin), 64%);
    --brown-second: hsl(var(--brown-origin), 32%);
    --brown-light: hsl(var(--brown-origin), 85%);

    --yellow-origin: 49, 85%;
    --yellow: hsl(var(--yellow-origin), 72%);
    --yellow-second: hsl(var(--yellow-origin), 50%);
    --yellow-light: hsl(var(--yellow-origin), 91%);

    --white-origin: 0, 0%;
    --white: hsl(var(--white-origin), 84%);
    --white-second: hsl(var(--white-origin), 100%);
    --white-light: hsl(var(--white-origin), 94%);

    --red-origin: 15, 92%;
    --red: hsl(var(--red-origin), 75%);
    --red-second: hsl(var(--red-origin), 50%);
    --red-light: hsl(var(--red-origin), 91%);

    --green-origin: 93, 52%;
    --green: hsl(var(--green-origin), 71%);
    --green-second: hsl(var(--green-origin), 56%);
    --green-light: hsl(var(--green-origin), 87%);

    //nasleduji velikosti pisma

    --font-size-small: 12px;
    --font-size-medium-small: 14px;
    --font-size-medium: 16px;
    --font-size-large: 20px;
    --font-size-xlarge: 26px;
    --font-size-xxlarge: 36px;

    //box shadows
    --inner-box-shadow: inset -6px -6px 15px #ffffff, 0px 0px 0px #ffffff,
        inset 6px 6px 10px #d4d4d4, 0px 0px 0px #d4d4d4;
    --outer-box-shadow: inset 0px 0px 0px #ffffff, -6px -6px 15px #ffffff, inset 0px 0px 0px #d4d4d4,
        6px 6px 10px #d4d4d4;
    --middle-box-shadow: inset 0px 0px 0px #ffffff, 0px 0px 0px #ffffff, inset 0px 0px 0px #d4d4d4,
        0px 0px 0px #d4d4d4;

    // selekce komponent react-flow
    --selection-box-shadow: 0 0 0 2px var(--primary-dark);

    //border radius
    --border-radius-smaller: 16px;
    --border-radius-bigger: 34px;
    --border-radius-large: 40px;

    //transitions
    --transition-short: 0.2s;
    --transition-long: 0.5s;

    //paddingy a marginy
    --padding-big: 20px;
    --padding-small: 10px;
    --inner-component-margin: 5px;

    //velikost čtverečků ikon v headeru
    --header-icons-size: 40px;
    --margin-icons: 14px;
}
