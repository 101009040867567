.load-container {
    width: 100%;
    height: 100%;
    margin: 0;
    background-color: #f3f3f3;
}
.load-text {
    position: absolute;
    text-align: center;
    text-transform: uppercase;
    color: #c2c2c2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: Arial, Helvetica, sans-serif;
    font-size: var(--font-size-large);
    padding-bottom: 160px;
}
.lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
}
.lds-dual-ring:after {
    content: " ";
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    /* margin: 8px; */
    border-radius: 50%;
    border: 4px solid #c2c2c2;
    border-color: #c2c2c2 transparent #c2c2c2 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
    top: 50%;
    left: 50%;
    margin: -40px 0 0 -37px;
}
@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
