.text-component input {
    padding: 1px !important;
    min-height: 0 !important;
}

.text-component {
    &.left {
        input {
            text-align: left;
        }
    }

    &.center {
        input {
            text-align: center;
        }
    }

    &.right {
        input {
            text-align: right;
        }
    }
}

.bold input {
    font-weight: bold;
}

.italic input {
    font-style: italic;
}

.text-GRAY {
    background: var(--bckg) !important;
}

.text-BLUE {
    background: var(--default-blue) !important;
}

.text-SUCCESS {
    background-color: var(--success) !important;
}

.text-WARNING {
    background-color: var(--warning) !important;
}

.text-ERROR {
    background-color: var(--error) !important;
    .dx-texteditor-input {
        color: white;
    }
}
