@import "../css/globalSass.scss";

.upper-menu-outer {
    height: var(--header-height);
    justify-content: center;

    .upper-menu-inner {
        height: var(--header-height);
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding-left: 10px;
        padding-right: 10px;

        .header-button {
            cursor: pointer;
            display: flex;
            align-items: center;

            span {
                font-size: var(--font-size-xlarge);
                margin: 4px;
            }

            svg {
                fill: var(--gray-medium);
                transition: fill 1s;
            }
        }

        .left-group-outer {
            flex-grow: 1;
            width: 100%;

            .left-group {
                display: grid;

                .navigation-name {
                    display: flex;
                    width: 100%;

                    &.navigation-open {
                        .burger {
                            .first {
                                transform: translate(0px, 8px) rotate(-225deg);
                            }
                            .second {
                                transform: rotateY(90deg);
                            }
                            .third {
                                transform: translate(0px, -8px) rotate(225deg);
                            }
                        }
                    }

                    .burger {
                        @include header-icon;

                        display: flex;
                        flex-direction: column;
                        position: relative;
                        margin-right: var(--margin-icons);

                        svg {
                            transition: transform 1s;
                            margin: 2px;
                            width: 36px;
                            height: 4px;

                            line {
                                stroke: var(--gray-medium);
                                transition: stroke 1s;
                            }
                        }

                        &:hover {
                            @include animation-short;
                            @include inner-box-shadow-smaller;
                        }
                    }

                    .fluid {
                        min-width: 0;

                        .fluid-item {
                            padding-left: 5px;
                            font-size: var(--font-size-large);
                            font-weight: bold;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }

                    .static {
                        .static-item {
                            width: 10px;
                        }
                    }
                }
            }
        }

        .right-group-outer {
            flex-grow: 1;
            width: 100%;
            display: flex;
            justify-content: right;

            .right-group {
                display: flex;

                .settings {
                    @include header-icon;

                    svg {
                        height: 25px;
                        width: 25px;
                    }

                    &:hover {
                        @include animation-short;
                        @include inner-box-shadow-smaller;
                    }
                }

                .profile {
                    @include header-icon;

                    margin-left: var(--margin-icons);

                    svg {
                        height: 22px;
                        width: 22px;
                    }

                    &.profile-popup-visible {
                        opacity: 0.8;
                    }

                    &:hover {
                        @include animation-short;
                        @include inner-box-shadow-smaller;
                    }
                }
            }
        }
    }
}
