@font-face {
    font-family: "Roboto";
    src: url("../fonts/Roboto/Roboto-Light.ttf") format("truetype");
    font-weight: lighter;
    font-style: normal;
}

@font-face {
    font-family: "Roboto";
    src: url("../fonts/Roboto/Roboto-LightItalic.ttf") format("truetype");
    font-weight: lighter;
    font-style: italic;
}

@font-face {
    font-family: "Roboto";
    src: url("../fonts/Roboto/Roboto-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Roboto";
    src: url("../fonts/Roboto/Roboto-Italic.ttf") format("truetype");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "Roboto";
    src: url("../fonts/Roboto/Roboto-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}

body {
    margin: 0;
    font-family: "Roboto";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--bckg);
}

text {
    font-family: "Roboto";
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

//chrome scrollbar
::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background: #ddd;
}

::-webkit-scrollbar-thumb {
    background: #666;
}
