.tank {
    fill-rule: evenodd;
    clip-rule: evenodd;
    stroke-linejoin: round;
    stroke-miterlimit: 1.5;

    .clip-rect {
        transition: height 3s;
        transform: rotatex(180deg) translate3d(0, -99.2%, 0); /* the Y-Value shall be higher than the height on hover*/
        transform-origin: top;
    }
    .tank-stroke {
        fill: none;
        stroke: var(--gray-dark);
        stroke-width: 16.67px;
    }
    //u svg class walls a water
}

.tank-BLUE {
    stop-color: var(--blue);
}

.tank-BROWN {
    stop-color: var(--brown);
}

.tank-YELLOW {
    stop-color: var(--yellow);
}

.tank-WHITE {
    stop-color: var(--white);
}

.tank-RED {
    stop-color: var(--red);
}

.tank-GREEN {
    stop-color: var(--green);
}
