@import "../../../../../css/globalSass.scss";

.component-background {
    padding: var(--padding-small);
    background-color: white;
}

.default-component {
    background-color: var(--primary);
}

.component-outer {
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    flex-flow: column;
    overflow: auto;
}

.component-inner {
    @extend .component-background;
    width: 100%;
    border-radius: var(--border-radius-smaller);
    box-sizing: border-box;
}

.common-component {
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    flex-flow: column;
    border-radius: var(--border-radius-smaller);
    box-sizing: border-box;
    justify-content: center;

    &-background {
        @extend .component-background;
    }
}

// react-draggable se uplatni pouze, pokud je zapnut editacni rezim - draggable
.react-draggable .common-component:active {
    opacity: 0.2;
}

.not-visible {
    display: none;
}

.flow-edit-mode {
    .not-visible {
        display: flex;
        opacity: 0.3;
    }
}
